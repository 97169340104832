import React from "react"
import Layout from "../components/layout"
import Item from "../components/item"
import { useStaticQuery, graphql } from "gatsby"


export default props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          skills {
            title
            description
          }
        }
      }
    }
  `)
  const skills = data.site.siteMetadata.skills.map((item, index) => (
    <Item
      key={index}
      name={item.title}
      moreInfo={item.description}
    />
  ))
  return (
    <Layout pageTitle="Skills" pageDescription="Learn more about my skills">
      {skills}
    </Layout>
  )
}
